<template>
  <b-card-code
    title="Quizzes"
    no-body
  >
    <b-overlay
      :show="showOverlay"
      rounded="sm"
    >
    
    <b-col
      md="12"
      class="my-1"
    >
    <!--
      <b-button    
        variant="primary"
        class="mb-1 float-right"
        to="/create-course"
      >
            Create Lesson
      </b-button>
      -->
    </b-col>
    <b-table
      :fields="fields"
      :items="quizLists"
      responsive
      class="mb-0"
    >

      <!-- A virtual column -->
      <template #cell(Sr#)="data">
        {{ data.index + 1 }}
      </template>

      <!-- A custom formatted column -->
      <template #cell(name)="data">
        <span class="text-nowrap">{{ data.value }}</span>
      </template>

      <template #cell(id)="data">
        <div class="text-nowrap">
            <b-button variant="outline-primary" :to="'/mcqs/'+data.value+''" size="sm"><b-icon icon="book-half"></b-icon></b-button>
        </div>
      <!--
        <b-row>
            <b-col lg="3" class="pb-2"><b-button variant="outline-primary" :to="'/edit-course/'+data.value+''" size="sm"><b-icon icon="eye-fill"></b-icon></b-button></b-col>
        </b-row>  
        -->      
     </template>
    </b-table>
    <!-- pagination -->
    
        <b-col>
      
      
    <record-count :to ='toRecordCount' :from='fromRecordCount' :total='totalRecordCount'></record-count>
    <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :base-url="path"
        :per-page="perPageRecord"
        :align="selected"
        @input = "changePage(currentPage)"
        class = "mb-4"
      />
    </b-col>
      
    </b-overlay>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { BTable, BProgress,BButton, BBadge, BDropdown, BDropdownItem, BPagination, BOverlay, BRow , BCol, BLink, BIcon} from 'bootstrap-vue'
import QuizService from '@/services/quiz.service.js';

export default {
  components: {
    BCardCode,
    BTable,
    BProgress,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    BRow,
    BCol,
    BButton,
    BLink,
    BIcon
  },
  data() {
    return {
      fields: [
        // A virtual column that doesn't exist in items
        'Sr#',
        // A column that needs custom formatting
        { key: 'name', label: 'Username' },
        { key: 'multiple_choice_questions_count', label: 'Question Count' },
        { key: 'id', label: 'Action' },
      ],
      quizLists: [],
      marginePage: 10,
      initialPage: 0,
      pageRange: 10,
      pageCount: 0,
      currentPage: 0,
      srCounter: 0,
      perPageRecord: 0,        
      toRecordCount: 0,
      fromRecordCount: 0,
      totalRecordCount: 0,
      id: this.$route.params.lessonId,
      selected: 'right',
      currentPage: 1,
      rows: 0,
      path: '',
      showOverlay: false,
    }
  },
  watch:{
        '$route'(to) {
            this.id = to.params.lessonId;
        } 
    },
  methods: {
    getQuizzes() {
        this.showOverlay = true;
        console.log(this.id)
        QuizService.quizByLesson(
            this.currentPage,
            this.id
        ).then(response => {
            console.log(response)
            this.quizLists = response.data.data.quizzes.data;
            this.rows = response.data.data.quizzes.total;
            this.currentPage = response.data.data.quizzes.current_page
            this.path = response.data.data.quizzes.path
            this.pageCount = response.data.data.quizzes.last_page;
            this.currentPage = response.data.data.quizzes.current_page;
            this.perPageRecord = response.data.data.quizzes.per_page;
            this.toRecordCount = response.data.data.quizzes.to;
            this.fromRecordCount = response.data.data.quizzes.from;
            this.totalRecordCount = response.data.data.quizzes.total;
            this.srCounter = (this.currentPage-parseInt(1)) * this.perPageRecord;
            this.showOverlay = false;
        }).catch(error => {
            this.showOverlay = false;
            console.log(error);
        });
    },
    changePage(event) {
      this.getQuizzes(this.currentPage);
    },
    
  },
  created() {
    this.getQuizzes(this.currentPage);
  }
}
</script>
