import axiosIns from '@/libs/axios.js';

class QuizService {
    quizByLesson(pageNumber=1, lessonId){
        return axiosIns.get(`quiz-lesson/${lessonId}?page=${pageNumber}`).then(response => {
            return Promise.resolve(response);
        }).catch(error => {
            return Promise.reject(error);
        })
    }
}

export default new QuizService();